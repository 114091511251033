import { useRunChecklistStore } from '@/stores/run/run-checklist.store';
import { Run } from '@/types/run.type';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import RunBuilderMenuComponent from './run-builder-menu.component';
import { RunChecklistTree } from './run-by-id.component';
import ClassicViewRunBuilderComponent from './views/classic-view/classic-view-run-builder.component';

interface RunBuilderComponentProps {
  runCompleted: boolean;
  runObject: Run;
  runChecklistTree: RunChecklistTree | undefined;
}

export default function RunBuilderComponent({
  runCompleted,
  runObject,
  runChecklistTree
}: RunBuilderComponentProps) {
  // Variables

  // Store
  const [setRunChecklistObject, setRunChecklistTree, isPreview, isPublic] =
    useRunChecklistStore(
      s => [
        s.setRunChecklistObject,
        s.setRunChecklistTree,
        s.isPreview,
        s.isPublic
      ],
      shallow
    );

  useEffect(() => {
    if (runObject && runChecklistTree) {
      setRunChecklistObject(runObject);
      setRunChecklistTree(runChecklistTree);
    }
  }, [runObject, runChecklistTree, setRunChecklistObject, setRunChecklistTree]);

  return (
    <>
      <div className="h-full w-full flex flex-col">
        {/* Menu */}
        {!isPreview && !isPublic && (
          <div className="sticky top-0 z-10">
            <RunBuilderMenuComponent title={runObject.name || ''} />
          </div>
        )}

        {/* Body */}
        <div className="flex-1 cl_primary_background">
          {/* Classic view */}
          <ClassicViewRunBuilderComponent runCompleted={runCompleted} />
        </div>
      </div>
    </>
  );
}
