import React, { useRef, useState } from 'react';

interface CLTooltipProps {
  content: string;
  children: React.ReactNode;
  className?: string;
  position?: string;
  isHtmlContent?: boolean; // New prop to indicate if content is HTML
}

export default function CLTooltip({
  content,
  children,
  className,
  position,
  isHtmlContent = false // Default to false
}: CLTooltipProps) {
  // Variables
  const [show, setShow] = useState(false);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const handleMouseOver = () => {
    setShow(true);
  };

  const handleMouseOut = () => {
    setShow(false);
  };

  return (
    <div
      className={'relative h-full ' + className}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
    >
      <div className="h-full" ref={tooltipRef}>
        {children}
      </div>

      {show && (
        <div
          style={{
            position: 'absolute',
            bottom: position == 'top' || !position ? '100%' : '',
            top: position == 'bottom' ? '100%' : '',
            left: '50%',
            transform: 'translateX(-50%)'
          }}
          className="px-2 py-1 my-1 bg-gray-700 text-white rounded text-sm text-center z-10 whitespace-nowrap"
        >
          {isHtmlContent ? (
            <div dangerouslySetInnerHTML={{ __html: content }} />
          ) : (
            content
          )}
        </div>
      )}
    </div>
  );
}
