import { NhostClient } from '@nhost/react';

export const syncAuth = async (nhost: NhostClient) => {
  const isAuthenticated = await nhost.auth.isAuthenticatedAsync(); // Ensure auth is ready
  const session = nhost.auth.getSession();
  // console.log(isAuthenticated, session);

  if (isAuthenticated && session?.refreshToken) {
    // console.log('🔹 Sending session to content script:', session);

    window.postMessage(
      { type: 'SYNC_AUTH', session },
      '*' // Send to any listeners (your content script)
    );
  }
};

// Listen for session changes and send updates
export const listenForAuthChanges = (nhost: NhostClient) => {
  nhost.auth.onAuthStateChanged(async session => {
    const isAuthenticated = await nhost.auth.isAuthenticatedAsync(); // Ensure auth is ready
    // console.log(isAuthenticated, session);

    if (isAuthenticated && session === 'SIGNED_IN') {
      syncAuth(nhost);
    }

    if (
      (isAuthenticated && session !== 'SIGNED_IN') ||
      session == 'SIGNED_OUT'
    ) {
      // console.log(
      //   '🔹 Auth state changed, sending session to content script:',
      //   session
      // );

      if (!session) {
        // console.warn('⚠️ No session available, retrying in 1 second...');
        setTimeout(() => syncAuth(nhost), 1000);
        return;
      }

      window.postMessage({ type: 'SYNC_AUTH', session }, '*');
    }
  });
};
