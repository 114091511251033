import '@/styles/globals.scss';

import { NhostClient, NhostProvider } from '@nhost/nextjs';

import { MyLayouts } from '@/types/my-layouts.type';
import { MyAppProps } from '@/types/my-page.type';
import SessionAuth from '@/wrappers/auth/session-auth';
import { NhostApolloProvider } from '@nhost/react-apollo';

import ErrorPopupComponent from '@/components/utils/error-popup.component';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { listenForAuthChanges, syncAuth } from '@/services/utils/sync-auth';

const ProtectedAxiosComponent = dynamic(
  () => import('../services/axios/Axios')
);

const nhost = new NhostClient({
  subdomain: process.env.NEXT_PUBLIC_NHOST_SUBDOMAIN || '',
  region: process.env.NEXT_PUBLIC_NHOST_REGION || ''
});

// const nhost = new NhostClient({
//   authUrl: process.env.NEXT_PUBLIC_NHOST_AUTH_URL,
//   graphqlUrl: process.env.NEXT_PUBLIC_NHOST_GRAPHQL_URL,
//   storageUrl: process.env.NEXT_PUBLIC_NHOST_STORAGE_URL,
//   functionsUrl: process.env.NEXT_PUBLIC_NHOST_FUNCTIONS_URL
// });

// Check that PostHog is client-side (used to handle Next.js SSR)
if (
  typeof window !== 'undefined' &&
  process.env.NEXT_PUBLIC_POSTHOG_KEY &&
  process.env.NEXT_PUBLIC_PRODUCTION === 'true'
) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    // Enable debug mode in development
    loaded: posthog => {
      if (process.env.NODE_ENV === 'development') posthog.debug();
    }
  });
}

export default function App({ Component, pageProps }: MyAppProps) {
  // Variables
  const router = useRouter();
  const MyLayout = Component.layout ? MyLayouts[Component.layout] : '';

  useEffect(() => {
    syncAuth(nhost);
    listenForAuthChanges(nhost);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const script = document.createElement('script');
      const executionScript = document.createElement('script');

      script.text = `!(function (e, t, n) {
                function a() {
                  var e = t.getElementsByTagName("script")[0],
                    n = t.createElement("script");
                  (n.type = "text/javascript"),
                    (n.async = !0),
                    (n.src = "https://beacon-v2.helpscout.net"),
                    e.parentNode.insertBefore(n, e);
                }
                if (
                  ((e.Beacon = n =
                    function (t, n, a) {
                      e.Beacon.readyQueue.push({ method: t, options: n, data: a });
                    }),
                  (n.readyQueue = []),
                  "complete" === t.readyState)
                )
                  return a();
                e.attachEvent
                  ? e.attachEvent("onload", a)
                  : e.addEventListener("load", a, !1);
              })(window, document, window.Beacon || function () {});`;

      document.body.appendChild(script);

      executionScript.text = `window.Beacon('init', 'dafb4f08-4312-4bec-873a-e7f9aa5939af')`;
      document.body.appendChild(executionScript);

      // Cleanup on component unmount
      return () => {
        document.body.removeChild(script);
        document.body.removeChild(executionScript);
      };
    }
  }, []);

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return (
    <PostHogProvider client={posthog}>
      <NhostProvider nhost={nhost} initial={pageProps.nhostSession}>
        <NhostApolloProvider nhost={nhost}>
          {/* Initialize Axio */}
          <ProtectedAxiosComponent />

          {/* Error component */}
          <ErrorPopupComponent></ErrorPopupComponent>

          {/* Render custom layout */}
          {Component.isNotProtected ? (
            <>
              {MyLayout ? (
                <MyLayout>
                  <Component {...pageProps} />
                </MyLayout>
              ) : (
                <Component {...pageProps} />
              )}
            </>
          ) : (
            <SessionAuth>
              {MyLayout ? (
                <MyLayout>
                  <Component {...pageProps} />
                </MyLayout>
              ) : (
                <Component {...pageProps} />
              )}
            </SessionAuth>
          )}
        </NhostApolloProvider>
      </NhostProvider>
    </PostHogProvider>
  );
}
