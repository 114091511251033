import { useRunChecklistStore } from '@/stores/run/run-checklist.store';
import { shallow } from 'zustand/shallow';
import ClassicViewTaskListComponent from './classic-view-task-list.component';

export default function ClassicViewRunBuilderComponent({
  runCompleted
}: {
  runCompleted: boolean;
}) {
  // Variables

  // Store
  const [isPreview, isPublic] = useRunChecklistStore(
    s => [s.isPreview, s.isPublic],
    shallow
  );

  return (
    <>
      <div
        className={
          'max-w-[1440px] mx-auto w-full h-full overflow-auto ' +
          (isPreview && !isPublic ? 'p-0' : 'px-4 md:px-10 py-4')
        }
      >
        {/* Task view */}
        <ClassicViewTaskListComponent runComplete={runCompleted} />
      </div>
    </>
  );
}
