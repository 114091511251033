import { gql } from '@apollo/client';

export const GET_ORGANIZATION_QUERY = gql`
  query GetOrganization($userId: uuid!) {
    organization(where: {org_users: {user_id: {_eq: $userId}}}) {
      id
      name
      website
      size
      industry
      org_users(where: {user_id: {_eq: $userId}}) {
        is_default
        user_job_role
        custom_role {
          id
          title
          custom_role_permissions {
            custom_permission_id
          }
        }
      }
      pricing_subscription {
        pricing_plan {
          name
          pricing_plan_type {
            name
            is_freeplan
          }
          pricing_limitations {
            type
            value_type
            value
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_BY_ID_QUERY = gql`
  query GetOrganizationById($id: uuid!) {
    organization(where: {id: {_eq: $id}}) {
      id
      name
      website
      size
      industry
    }
  }
`;

export const CREATE_ORGANIZATION_AND_ADD_A_MEMBER_MUTATION = gql`
  mutation CreateOrganization($organization: CreateOrganizationsOrganizationInsertInput!) {
    CreateOrganizations(organization: $organization) {
      returning {
        id
        name
      }
      affected_rows
    }
  }
`;

export const ADD_MEMBER_TO_ORGANIZATION_MUTATION = gql`
  mutation InviteAMemberToOrganization($orgId: uuid!, $userId: uuid!, $customRoleId: uuid!){
    insert_org_users_one(object: {
      org_id: $orgId,
      user_id: $userId,
      custom_role_id: $customRoleId
      }) {
      id
      org_id
    }
  }
`;

export const DELETE_ORG_MEMBER_MUTATION = gql`
  mutation DeleteOrgMemberByIdMutation($id: uuid!) {
    delete_org_users_by_pk (
      id: $id
    ) {
      id
    }
  }
`;

export const DELETE_ORG_MEMBER_AND_PROJECT_USERS_MUTATION = gql`
  mutation DeleteOrgMemberAndProjectUsersByIdMutation($id: uuid!, $orgId: uuid!, $userId: uuid!) {
    delete_org_users_by_pk(id: $id) {
      id
    }
    delete_project_users(
      where: { project: { org_id: { _eq: $orgId } }, user_id: { _eq: $userId } }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_ORG_MEMBER_ROLE_BY_ID_MUTATION = gql`
  mutation updateOrgMemberRoleByIdMutation($id: uuid!, $set: org_users_set_input!) {
    update_org_users_by_pk(
      pk_columns: { id: $id }, 
      _set: $set) {
      id
    }
  }
`;

export const GET_ORGANIZATION_PROJECTS_QUERY = gql`
  query GetOrganizationProjects($id: uuid!, $userId: uuid!) {
      projects(where: {org_id: {_eq: $id}, _and: {is_deleted: {_is_null: true}}}, order_by: {created_at: asc}) {
        id
        name
        project_users(where: {user_id: {_eq: $userId}}) {
          id
          custom_role {
            id
            title
            custom_role_permissions {
              custom_permission_id
            }
          }
        }
      }
    }
`;

export const UPDATE_ORGANIZATION_NAME_MUTATION = gql`
  mutation UpdateOrganizationName ($id: uuid!, $set: organization_set_input!) {
    update_organization_by_pk(
      pk_columns: { id: $id }, 
      _set: $set) {
      id
      name
    }
  }
`;

export const GET_ORGANIZATION_BY_ID_WITH_PROJECTS_QUERY = gql`
  query GetOrganizationByIdWithProjects($id: uuid!) {
    organization(where: {id: {_eq: $id}}) {
      id
      name
      projects {
        id
        name
      }
    }
  }
`;

export const INVITE_MEMBERS_TO_ORGANIZATION_AND_PROJECT_MUTATION = gql`
mutation InviteAMemberToOrganizationAndProjectMutation($orgInvites: [InviteMembersToOrganizationAndProjectOrgInvitesInsertInput!]!, $projectInvites: [InviteMembersToOrganizationAndProjectProjectInvitesInsertInput!]!) {
  InviteMembersToOrganizationAndProject(orgInvites: $orgInvites, projectInvites: $projectInvites) {
    insert_org_invites {
      affected_rows
      returning {
        id
        org_id
        email
        custom_role_id
      }
    }
    insert_project_invites {
      affected_rows
      returning {
        id
        project_id
        email
      }
    }
  }
}
`;

export const INVITE_MEMBERS_TO_ORGANIZATION_MUTATION = gql`
  mutation InviteAMemberToOrganizationMutation($orgInvites: [org_invites_insert_input!]!) {
    insert_org_invites(
        objects: $orgInvites,
        on_conflict: {
          constraint: org_invites_org_id_email_key,
          update_columns: []
        }
    ) {
        affected_rows
        returning {
          id
          email
          custom_role_id
          custom_role {
            id
            title
          }
        }
    }
  }
`;

export const GET_INVITE_BY_ORGANIZATION_ID_QUERY = gql`
  query GetInviteByOrganizationId($orgId: uuid!, $email: citext) {
    org_invites(where: {org_id: {_eq: $orgId}, _and: {email: {_eq: $email}}}) {
      id
      org_id
      email
      custom_role_id
    }

    project_invites(where: {org_id: {_eq: $orgId}, _and: {email: {_eq: $email}}}) {
      id
      org_id
      project_id
      email
      custom_role_id
    }

    organization(where: {id: {_eq: $orgId}}) {
      id
      name
    }
  }
`;

export const ACCEPT_ORGANIZATION_INVITE_MUTATION = gql`
  mutation AcceptOrganizationInviteMutation($orgUsers: [org_users_insert_input!]!, $projectUsers: [project_users_insert_input!]!){
    insert_org_users(
      objects: $orgUsers
    ) {
      affected_rows
    }

    insert_project_users(
      objects: $projectUsers
    ) {
      affected_rows
    }
  }
`;

export const DELETE_ORG_INVITE_MUTATION = gql`
  mutation DeleteOrgInviteByIdMutation($id: uuid!) {
    delete_org_invites_by_pk (
      id: $id
    ) {
      id
    }
  }
`;

export const GET_ORG_USERS_AND_PROJECT_USERS_AND_PROJECT_INVITES_QUERY = gql`
  query getOrgUsersAndProjectUserAndProjectInvites($orgId: uuid!, $projectId: uuid!) {
    org_users(where: {org_id: {_eq: $orgId}}) {
      id
      user {
        id
        displayName
        email
        avatarUrl
      }
      custom_role_id
      custom_role {
        id
        title
      }
    }

    project_users(where: {project_id: {_eq: $projectId}}) {
      id
      user {
        id
        displayName
        email
        avatarUrl
      }
      custom_role_id
      custom_role {
        id
        title
      }
    }

    project_invites(where: {project_id: {_eq: $projectId}}) {
      id
      org_id
      project_id
      email
      custom_role_id
      custom_role {
        id
        title
      }
    }
  }
`;

export const GET_ORG_USERS_AND_ORG_INVITES_QUERY = gql`
  query getOrgUsersQuery($orgId: uuid!) {
    org_users(where: {org_id: {_eq: $orgId}}) {
      id
      user {
        id
        displayName
        email
        avatarUrl
      }
      custom_role_id
      custom_role {
        id
        title
      }
    }

    org_invites(where: {org_id: {_eq: $orgId}}) {
      id
      email
      custom_role_id
      custom_role {
        id
        title
      }
    }
  }
`;

export const GET_ORGANIZATION_SUBSCRIPTION_QUERY = gql`
  query GetOrganizationSubscriptionQuery($orgId: uuid!){
    organization(where: {id: {_eq: $orgId}}) {
      id
      name
      stripe_account_id
      pricing_subscription {
        subscription_id
        pricing_plan_id
        status
        qty
        canceled_at
        cancel_at_period_end
        cancel_at
        current_period_start
        current_period_end
        pricing_plan {
          id
          name
          pricing_currency {
            id
            name
          }
          pricing_period { 
            id
            name
          }
          pricing_plan_type {
            id
            name
            is_freeplan
            is_lifetime
            is_enterprise
          }
          pricing_limitations {
            id
            type
            value_type
            value
          }
          pricing_price {
            id
            amount
            actual_amount
            status
            product_id
          }
        }
      }
      org_users_aggregate {
        aggregate {
          count
        }
      }
      org_invites_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_ORG_USERS_QUERY = gql`
  query getOrgUsersQuery($orgId: uuid!) {
    org_users(where: {org_id: {_eq: $orgId}}) {
      id
      user {
        id
        displayName
        email
        avatarUrl
      }
      custom_role_id
      custom_role {
        id
        title
      }
    }
  }
`;

export const GET_ORGANIZATION_USAGE_QUERY = gql`
  query OrgChecklistCompletionQuery($orgId: uuid!) {
      checklist_created: events_aggregate(where: {org_id: {_eq: $orgId}, event_type: {_eq: "checklist_created"}, is_project_deleted: {_is_null: true}}) {
          aggregate {
              count
          }
      }

      run_completed: events_aggregate(where: {org_id: {_eq: $orgId}, event_type: {_eq: "run_completed"}, is_project_deleted: {_is_null: true}}) {
          aggregate {
              count
          }
      }

      agent_managers_aggregate(where: {org_id: {_eq: $orgId}}) {
        aggregate {
          count
          }
  }
  }
`;