import { CLPrimaryCustomLabel } from '@/components/ui-controls';
import CLButton from '@/components/ui-controls/default-ui-controls/button';
import CommonConstants from '@/constants/common-constants';
import { UPDATE_RUN_CHECKLIST_STATUS_MULTIPLE_MUTATION } from '@/graphql/runs.gql';
import {
  markParentAsCompleted,
  markParentAsNotCompleted,
  markAllChildAsCompletedOrNotCompleted as markSelfAndAllChildAsCompletedOrNotCompleted
} from '@/services/runs/runs.service';
import { usePublicRunStore } from '@/stores/public-run/public-run-checklist.store';
import { useRunChecklistStore } from '@/stores/run/run-checklist.store';
import { RunChecklist } from '@/types/run.type';
import { useMutation } from '@apollo/client';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { useUserId } from '@nhost/nextjs';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import CLTooltip from '../../../utils/tooltip';
import { RunChecklistTree } from '../../run-by-id.component';
import TaskDetailsPopup from './task-details.popup.component';

export default function ClassicViewTaskListComponent({
  runComplete
}: {
  runComplete: boolean;
}) {
  // Variables
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [runCompleted, setRunCompleted] = useState<boolean>(false);

  // Store
  const [
    runChecklistTree,
    statusIndicators,
    isPreview,
    isPublic,
    isBuilderPreview,
    isSingleResponse,
    runChecklistObject
  ] = useRunChecklistStore(
    s => [
      s.runChecklistTree,
      s.statusIndicators,
      s.isPreview,
      s.isPublic,
      s.isBuilderPreview,
      s.isSingleResponse,
      s.runChecklistObject
    ],
    shallow
  );

  function isRunChecklistFullyCompleted(
    checklistTree: RunChecklistTree
  ): boolean {
    if (!checklistTree || !checklistTree?.children) {
      return false;
    }

    for (const child of checklistTree.children) {
      // If any task is not completed, return false
      if (!child.checklist.is_completed) {
        return false;
      }

      // Recursively check child tasks
      if (!isRunChecklistFullyCompleted(child)) {
        return false;
      }
    }

    // If all tasks are completed, return true
    return true;
  }

  useEffect(() => {
    const isCompleted = isRunChecklistFullyCompleted(
      runChecklistTree as RunChecklistTree
    );

    setRunCompleted(isCompleted);

    if (!runComplete && isCompleted) {
      // Check if it changed from false -> true
      if (isCompleted) {
        setShowSuccessToast(true);

        const timer = setTimeout(() => {
          setShowSuccessToast(false);
        }, 3700);

        return () => clearTimeout(timer);
      }
    }
  }, [runChecklistTree?.checklist?.is_completed]);

  return (
    <>
      <div
        className={
          'bg-white overflow-auto rounded-lg min-h-full' +
          (isPublic ? ' p-4 lg:p-6' : isPreview ? ' p-4 pt-4' : ' p-4') +
          (isBuilderPreview
            ? ' rounded-none rounded-br-lg !pt-0 pl-0 pr-0 h-[10vh] bg-white'
            : '')
        }
      >
        {isBuilderPreview && (
          <div
            className="flex justify-between w-full text-black/50 font-semibold items-center px-2 pl-0 py-2 pb-4 text-sm h-4 z-10 sticky top-0 bg-white"
            style={{
              left: 0, // Align to the left edge
              marginLeft: 'calc(-1 * var(--tw-container-left, 0px))', // Adjust for container padding
              marginRight: 'calc(-1 * var(--tw-container-left, 0px))' // Adjust for container padding
            }}
          >
            <div className="text-sm">Preview</div>
            {/* <div className='text-xs items-start justify-start italic'>Any changes you make here won&apos;t be saved!</div> */}
          </div>
        )}
        {/* Header */}
        <div
          className={
            'flex flex-nowrap gap-2 mb-4 font-medium' +
            (isBuilderPreview ? ' sticky top-6 bg-white !gap-0 mb-0 z-10' : '')
          }
        >
          {/* Title */}
          <div
            className={
              'flex-1 lg:min-w-[300px] text-base ' +
              (isBuilderPreview
                ? 'text-black/70 flex pb-1 border-b-2 border-gray-300 !min-w-[230px]'
                : '')
            }
          >
            <div className="flex">
              <span
                style={
                  isBuilderPreview
                    ? {
                        alignItems: 'center',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        maxHeight: 'calc(1.3em * 2)',
                        lineHeight: '1.3em'
                      }
                    : isSingleResponse
                    ? { padding: '0 0.75rem' }
                    : undefined
                }
              >
                {!isSingleResponse
                  ? runChecklistTree?.checklist?.title || ''
                  : 'Tasks'}
              </span>
              {!isPreview && !isBuilderPreview && !isPublic && (
                <div className="ml-2 text-gray-500 text-[12px] px-2 h-fit !items-start whitespace-nowrap rounded-xl border border-gray-400">
                  {runCompleted ? 'Completed' : 'In-Progress'}
                </div>
              )}
            </div>
          </div>

          {/* Status indicators */}
          {statusIndicators?.map((statusIndicator, index) => (
            <div
              key={index}
              className={
                'hidden lg:block ' +
                (isBuilderPreview
                  ? 'max-w-[84px] min-w-[83px] pl-2 pr-0.5 text-sm text-black/70 pb-1 z-5 bg-white border-b-2 border-gray-300'
                  : 'min-w-[95px]')
              }
            >
              <div className="flex items-center justify-center text-center">
                <span className="whitespace-pre-wrap overflow-hidden break-words">
                  {statusIndicator?.name}
                </span>
              </div>
            </div>
          ))}
        </div>

        {showSuccessToast && !isPreview && !isBuilderPreview && !isPublic && (
          <>
            {/* Foreground Toast (Doesn't Move Other Components) */}
            {showSuccessToast && (
              <div className="absolute top-[70px] w-[80%] md:w-[70%] lg:w-[80%] left-1/2 md:left-[62%] lg:left-[58%] transform -translate-x-1/2 text-center bg-green-200 z-20 text-base px-4 py-3 rounded-lg shadow-lg animate-fade-in-out">
                <div className="fixed inset-0 flex items-center justify-center z-0 pointer-events-none">
                  <div className="absolute -left-[13%] w-screen h-screen">
                    {/* First Confetti */}
                    <DotLottieReact
                      src="/confetti1.json"
                      loop
                      autoplay
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '96%',
                        top: '39%',
                        left: 0
                      }}
                    />
                    {/* Second Confetti (Overlapping the first) */}
                    <DotLottieReact
                      src="/confetti2.json"
                      loop
                      autoplay
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '96%',
                        top: '39%',
                        left: 0
                      }}
                    />
                  </div>
                </div>
                {/* Success Message */}
                <span className="font-semibold">
                  {runChecklistObject?.name}
                </span>{' '}
                Completed 🎉
              </div>
            )}
          </>
        )}

        {showSuccessToast &&
          !isPreview &&
          !isBuilderPreview &&
          isPublic &&
          !isSingleResponse && (
            <>
              {/* Foreground Toast (Doesn't Move Other Components) */}
              {showSuccessToast && (
                <div className="fixed top-[70px] w-[90%] left-[5%] text-center bg-green-200 z-20 text-base px-4 py-3 rounded-lg shadow-lg animate-fade-in-out">
                  <div className="fixed inset-0 flex items-center justify-center z-0 pointer-events-none">
                    <div className="absolute -left-[50%] w-screen h-screen">
                      {/* First Confetti */}
                      <DotLottieReact
                        src="/confetti1.json"
                        loop
                        autoplay
                        style={{
                          position: 'absolute',
                          width: '200%',
                          height: '170%',
                          top: '3%',
                          left: 0
                        }}
                      />
                      {/* Second Confetti (Overlapping the first) */}
                      <DotLottieReact
                        src="/confetti2.json"
                        loop
                        autoplay
                        style={{
                          position: 'absolute',
                          width: '200%',
                          height: '170%',
                          top: '3%',
                          left: 0
                        }}
                      />
                    </div>
                  </div>
                  <span className="font-semibold">
                    {runChecklistObject?.name}
                  </span>{' '}
                  Completed 🎉
                </div>
              )}
            </>
          )}

        {/* List of tasks */}
        {runChecklistTree &&
          runChecklistTree?.children &&
          runChecklistTree?.children?.length > 0 && (
            <>
              <div>
                <RenderRunChecklist
                  node={runChecklistTree}
                  parentDisplayNumber={undefined}
                  isBuilderPreview={isBuilderPreview}
                />
              </div>
            </>
          )}
      </div>
    </>
  );
}

function RenderRunChecklist({
  node,
  parentDisplayNumber,
  isBuilderPreview,
  isChild
}: {
  node: RunChecklistTree;
  parentDisplayNumber: string | undefined;
  isBuilderPreview?: boolean;
  isChild?: boolean;
}) {
  return (
    <>
      {/* Loop all children  */}
      {node?.children?.map((childNode, index) => (
        <div key={childNode?.checklist?.id + '-' + index}>
          <RenderEachRunChecklist
            checklist={childNode?.checklist}
            node={childNode}
            displayNumber={(parentDisplayNumber || '') + (index + 1) + '.'}
            isBuilderPreview={isBuilderPreview}
            isChild={isChild}
          />

          {/* Does this node have children? */}
          {childNode?.children && childNode?.children?.length > 0 && (
            <div className={'pl-4' + (isBuilderPreview ? ' !pl-0' : '')}>
              <RenderRunChecklist
                node={childNode}
                parentDisplayNumber={
                  (parentDisplayNumber || '') + (index + 1) + '.'
                }
                isBuilderPreview={isBuilderPreview}
                isChild={true}
              />
            </div>
          )}
        </div>
      ))}
    </>
  );
}

function RenderEachRunChecklist({
  checklist,
  node,
  displayNumber,
  isBuilderPreview,
  isChild
}: {
  checklist: RunChecklist;
  node: RunChecklistTree;
  displayNumber: string | undefined;
  isBuilderPreview?: boolean;
  isChild?: boolean;
}) {
  // Variables

  return (
    <>
      {node?.children && node?.children?.length > 0 ? (
        <>
          {/* Parent checklist */}
          <RenderParentRunChecklist
            checklist={node?.checklist}
            node={node}
            displayNumber={displayNumber}
            isBuilderPreview={isBuilderPreview}
            isChild={isChild}
          />
        </>
      ) : (
        <>
          {/* Child checklist */}
          <RenderChildRunChecklist
            checklist={node?.checklist}
            node={node}
            displayNumber={displayNumber}
            isBuilderPreview={isBuilderPreview}
            isChild={isChild}
          />
        </>
      )}
    </>
  );
}

function RenderParentRunChecklist({
  checklist,
  node,
  displayNumber,
  isBuilderPreview,
  isChild
}: {
  checklist: RunChecklist;
  node: RunChecklistTree;
  displayNumber: string | undefined;
  isBuilderPreview?: boolean;
  isChild?: boolean;
}) {
  return (
    <>
      {/* Layout */}
      <div
        className={
          'group  min-w-fit rounded-lg cl_primary_background' +
          (isBuilderPreview
            ? ' my-3.5 py-3.5' + (isChild ? ' ml-4 ' : '')
            : ' my-4 py-4')
        }
      >
        {/* Task title */}
        <div className="flex items-center">
          <RunChecklistTaskTitle
            checklist={node?.checklist}
            node={node}
            displayNumber={displayNumber}
            isParent={true}
            isBuilderPreview={isBuilderPreview}
          />

          {/* Toolbar */}
          <div className="flex items-center min-h-[24px] mr-0.5">
            <div className="block lg:hidden lg:group-hover:block">
              <RunChecklistToolBar node={node} checklist={node?.checklist} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function RenderChildRunChecklist({
  checklist,
  node,
  displayNumber,
  isBuilderPreview,
  isChild
}: {
  checklist: RunChecklist;
  node: RunChecklistTree;
  displayNumber: string | undefined;
  isBuilderPreview?: boolean;
  isChild?: boolean;
}) {
  // Variables

  // Nhost hooks
  const userId = useUserId();

  // States
  const [isSaving, setIsSaving] = useState<boolean>(false);

  // Store
  const [
    statusIndicators,
    refreshRunChecklistTree,
    isPreview,
    isPublic,
    isStatusIndicatorDisabled
  ] = useRunChecklistStore(
    s => [
      s.statusIndicators,
      s.refreshRunChecklistTree,
      s.isPreview,
      s.isPublic,
      s.isStatusIndicatorDisabled
    ],
    shallow
  );
  const [publicRunAccessToken] = usePublicRunStore(
    s => [s.publicRunAccessToken],
    shallow
  );

  // GraphQL
  const [updateRunChecklistStatusMultipleMutation] = useMutation(
    UPDATE_RUN_CHECKLIST_STATUS_MULTIPLE_MUTATION
  );

  async function onChangeOfStatusIndicator(
    statusIndicatorId: string,
    status: boolean,
    selectedRunNode: RunChecklistTree
  ) {
    if (selectedRunNode) {
      const updatableRunChecklist: RunChecklist[] = [];

      // Mark self and all child as completed or not-completed
      markSelfAndAllChildAsCompletedOrNotCompleted(
        selectedRunNode,
        status,
        updatableRunChecklist,
        statusIndicatorId
      );

      // Mark all the parents as not-completed
      if (status === false) {
        markParentAsNotCompleted(selectedRunNode.parent, updatableRunChecklist);
      } else {
        // Mark parent as completed, if all other child status is completed
        markParentAsCompleted(selectedRunNode.parent, updatableRunChecklist);
      }

      // Refresh the checklist view also
      refreshRunChecklistTree();

      // Don't update the status on database, if it is preview
      if (!isPreview && !isPublic) {
        await updateRunChecklistStatus(updatableRunChecklist);
      } else if (isPublic) {
        await updatePublicRunChecklistStatus(updatableRunChecklist);
      }
    }
  }

  async function updateRunChecklistStatus(
    updatableRunChecklist: RunChecklist[]
  ) {
    setIsSaving(true);

    // Update completedBy
    updatableRunChecklist.map(runChecklist => {
      if (runChecklist?.is_completed) {
        runChecklist.completed_by = userId;
        runChecklist.completed_at = new Date().toISOString();
      } else {
        runChecklist.completed_by = undefined;
        runChecklist.completed_at = undefined;
      }

      return runChecklist;
    });

    // Insert and update
    const { data, errors } = await updateRunChecklistStatusMultipleMutation({
      variables: {
        runChecklist: updatableRunChecklist
      }
    });

    setIsSaving(false);
  }

  async function updatePublicRunChecklistStatus(
    updatableRunChecklist: RunChecklist[]
  ) {
    try {
      setIsSaving(true);

      // Update status
      const payload = {
        publicRunChecklist: updatableRunChecklist
      };

      // Resume the run
      const response = await axios.post(
        CommonConstants.API_PREFIX + '/public/run/update-status',
        payload,
        {
          headers: {
            authorization: 'Bearer ' + publicRunAccessToken
          }
        }
      );

      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);

      throw error;
    }
  }

  return (
    <>
      <div
        className={
          'group  min-w-fit rounded-lg hover:bg-gray-100' +
          (isBuilderPreview ? ' my-2 py-2' : ' my-4 py-4')
        }
      >
        {/* Task title and checkbox */}
        <div className="flex flex-nowrap gap-4 lg:gap-2 flex-col lg:flex-row">
          {/* Task title */}
          <div
            className={
              'flex-1 lg:min-w-[300px] flex items-center relative' +
              (isBuilderPreview ? ' !min-w-[230px]' : '')
            }
          >
            <RunChecklistTaskTitle
              checklist={node?.checklist}
              node={node}
              displayNumber={displayNumber}
              isParent={false}
              isBuilderPreview={isBuilderPreview}
              isChild={isChild}
            />

            {/* Toolbar */}
            <div className="flex items-center min-h-[24px] absolute right-[3px] lg:right-[-16px] top-[0px] bg-transparent">
              <div className="block lg:hidden lg:group-hover:block">
                <RunChecklistToolBar
                  node={node}
                  checklist={node?.checklist}
                  onChangeOfStatusIndicator={onChangeOfStatusIndicator}
                  isSaving={isSaving}
                />
              </div>
            </div>
          </div>

          {/* Status indicators for desktop screen */}
          <div className="hidden lg:flex gap-2 xl:gap-3">
            {statusIndicators?.map((statusIndicator, index) => (
              <div
                key={index}
                className={
                  'flex justify-center ' +
                  (isBuilderPreview
                    ? 'min-w-[71px] max-w-[71px] text-sm'
                    : 'min-w-[100px]')
                }
              >
                <input
                  type="checkbox"
                  className={
                    'bg-white border-black text-[#474554] focus:ring-black/20 rounded cursor-pointer disabled:opacity-50 disabled:pointer-events-none' +
                    (isBuilderPreview ? ' w-5 h-5' : ' w-6 h-6')
                  }
                  checked={
                    (checklist?.is_completed &&
                      (statusIndicator.id ===
                        checklist.checklist_status_indicators_id ||
                        !statusIndicator.id)) ||
                    false
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeOfStatusIndicator(
                      statusIndicator?.id || '',
                      event.target.checked,
                      node
                    );
                  }}
                  disabled={isSaving || isStatusIndicatorDisabled}
                />
              </div>
            ))}
          </div>

          {/* Status indicators for mobile screen */}
          <div className="flex lg:hidden gap-4 flex-col px-4">
            {statusIndicators?.map((statusIndicator, index) => (
              <div key={index} className="flex gap-2">
                <input
                  type="checkbox"
                  id={
                    checklist?.id +
                    '-' +
                    statusIndicator?.id +
                    '-status-indicator'
                  }
                  className={
                    'bg-white border-black text-[#474554] focus:ring-black/20 rounded cursor-pointer disabled:opacity-50 disabled:pointer-events-none' +
                    (isBuilderPreview ? ' w-5 h-5' : ' w-6 h-6')
                  }
                  checked={
                    (checklist?.is_completed &&
                      (statusIndicator.id ===
                        checklist.checklist_status_indicators_id ||
                        !statusIndicator.id)) ||
                    false
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeOfStatusIndicator(
                      statusIndicator?.id || '',
                      event.target.checked,
                      node
                    );
                  }}
                  disabled={isSaving || isStatusIndicatorDisabled}
                />
                <CLPrimaryCustomLabel
                  className="flex items-center cursor-pointer pt-[2px]"
                  htmlFor={
                    checklist?.id +
                    '-' +
                    statusIndicator?.id +
                    '-status-indicator'
                  }
                >
                  {statusIndicator?.name}
                </CLPrimaryCustomLabel>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

function RunChecklistTaskTitle({
  checklist,
  node,
  displayNumber,
  isParent,
  isBuilderPreview,
  isChild
}: {
  checklist: RunChecklist;
  node: RunChecklistTree;
  displayNumber: string | undefined;
  isParent: boolean;
  isBuilderPreview?: boolean;
  isChild?: boolean;
}) {
  // Variables

  return (
    <>
      <div
        className={
          'flex flex-col gap-2 pl-4 pr-6 sm:pr-4' +
          (isBuilderPreview ? ' opacity-70' + (isChild ? ' !pl-8 ' : '') : '')
        }
      >
        {/* Title */}
        <div className={isParent ? 'font-medium' : ''}>
          {displayNumber} {checklist?.title}
        </div>
      </div>
    </>
  );
}

function RunChecklistToolBar({
  node,
  checklist,
  onChangeOfStatusIndicator,
  isSaving
}: {
  node: RunChecklistTree;
  checklist: RunChecklist;
  onChangeOfStatusIndicator?: (
    statusIndicatorId: string,
    status: boolean,
    selectedRunNode: RunChecklistTree
  ) => void;
  isSaving?: boolean;
}) {
  // Variables

  // States
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      {/* List of tabs */}
      <div className="flex gap-2">
        {/* Instructions */}
        {checklist?.id && (
          //  Details
          <div>
            <CLTooltip content="Details">
              <CLButton
                className="flex gap-1 justify-center  rounded-full w-6 h-6  items-center hover:bg-[#c1cdd9] "
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <svg
                  className="w-[18px] h-[18px]"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 3.5V4.1C2 4.32091 1.82091 4.5 1.6 4.5H1.4C1.17909 4.5 1 4.32091 1 4.1V3.4C1 2.90294 1.40294 2.5 1.9 2.5H4.87889C5.05657 2.5 5.23028 2.55259 5.37812 2.65115L6.65139 3.5H12.6C13.0971 3.5 13.5 3.90294 13.5 4.4V5.5H12.5V4.5H6.62111C6.44343 4.5 6.26972 4.44741 6.12188 4.34885L4.84861 3.5H2Z"
                    fill="#213547"
                    fillOpacity="0.8"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.86038 6.5L1.97434 12.1581L1.02566 11.8419L2.93449 6.1154C3.05699 5.74789 3.40092 5.5 3.7883 5.5H13.9877C14.5732 5.5 15.0028 6.05025 14.8608 6.61828L14.5821 7.73321C14.5285 7.94753 14.3113 8.07783 14.097 8.02425L13.903 7.97575C13.6887 7.92217 13.5584 7.70499 13.6119 7.49068L13.8596 6.5H3.86038Z"
                    fill="#213547"
                    fillOpacity="0.8"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 12V6.4C2 6.17909 1.82091 6 1.6 6H1.4C1.17909 6 1 6.17909 1 6.4V12.1C1 12.5971 1.40294 13 1.9 13H6.6C6.82091 13 7 12.8209 7 12.6V12.4C7 12.1791 6.82091 12 6.6 12H2Z"
                    fill="#213547"
                    fillOpacity="0.8"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.0001 8C12.1139 8 12.7022 8 13.3417 8.29633C14.0029 8.60271 14.6945 9.21629 14.9851 10.3787C15.2155 11.3002 14.9583 12.0602 14.4386 12.599C13.9406 13.1154 13.2327 13.4004 12.5708 13.495L12.5356 13.5H8.35383C8.15181 13.5 7.98151 13.3494 7.95683 13.1489L7.92201 12.8661C7.89732 12.6656 8.02666 12.4798 8.21847 12.4164C8.33084 12.3792 8.44012 12.3383 8.53689 12.2959C8.35582 12.1529 8.22632 11.9721 8.14825 11.7667C8.04436 11.4933 8.04351 11.2094 8.0806 10.9631C8.12903 10.6414 8.25077 10.3267 8.38274 10.068C8.48312 9.87121 8.73304 9.82198 8.91685 9.94453L9.08326 10.0555C9.26707 10.178 9.3145 10.4261 9.22298 10.6272C9.1516 10.784 9.09323 10.954 9.06945 11.1119C9.0482 11.2531 9.05983 11.3504 9.08302 11.4114C9.10109 11.459 9.13351 11.5077 9.22361 11.5528C9.44808 11.665 9.79226 11.8998 9.77457 12.3225C9.77189 12.3865 9.7605 12.4458 9.74353 12.5H12.463C12.9554 12.4234 13.4211 12.2135 13.7188 11.9049C14.0015 11.6117 14.1609 11.205 14.015 10.6213C13.8056 9.7837 13.3391 9.39728 12.9213 9.20366C12.4819 9.00006 12.0702 9 12.0001 9C11.9135 9 11.5692 9.00021 11.1932 9.05691C11.0051 9.08526 10.8221 9.12592 10.666 9.18172C10.4793 9.24852 10.2399 9.23987 10.0997 9.09963L9.92934 8.92928C9.77313 8.77307 9.77344 8.51645 9.9648 8.40607C10.0848 8.33683 10.2097 8.28289 10.3292 8.24014C10.5664 8.15532 10.8176 8.10223 11.0441 8.06808C11.4957 7.99999 11.9012 7.99999 12.0001 8ZM8.85208 12.4928C8.85208 12.4928 8.84763 12.4901 8.84178 12.4844C8.84966 12.4898 8.85208 12.4928 8.85208 12.4928Z"
                    fill="#213547"
                    fillOpacity="0.8"
                  />
                  <path
                    d="M10.8 10.8961C10.7913 10.368 10.0087 10.368 10 10.8961C10.0088 11.4242 10.7913 11.4242 10.8 10.8961Z"
                    fill="#213547"
                    fillOpacity="0.8"
                  />
                  <path
                    d="M12.1875 10.8961C12.1787 10.368 11.3962 10.368 11.3875 10.8961C11.3962 11.4242 12.1787 11.4242 12.1875 10.8961Z"
                    fill="#213547"
                    fillOpacity="0.8"
                  />
                  <path
                    d="M13.5757 10.8961C13.567 10.368 12.7845 10.368 12.7757 10.8961C12.7845 11.4242 13.567 11.4242 13.5757 10.8961Z"
                    fill="#213547"
                    fillOpacity="0.8"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.9 10.8977C10.8891 11.557 9.91094 11.557 9.90001 10.8977L9.89996 10.8944C9.91088 10.2352 10.8891 10.2352 10.9 10.8944V10.8977ZM12.2875 10.8977C12.2765 11.557 11.2984 11.557 11.2875 10.8977L11.2874 10.8944C11.2983 10.2352 12.2765 10.2352 12.2875 10.8944V10.8977ZM13.6757 10.8977C13.6648 11.557 12.6867 11.557 12.6757 10.8977L12.6757 10.8944C12.6866 10.2352 13.6648 10.2352 13.6757 10.8944V10.8977ZM10.8 10.8961C10.7913 10.368 10.0087 10.368 10 10.8961C10.0088 11.4242 10.7913 11.4242 10.8 10.8961ZM12.1875 10.8961C12.1787 10.368 11.3962 10.368 11.3875 10.8961C11.3962 11.4242 12.1787 11.4242 12.1875 10.8961ZM13.5757 10.8961C13.567 10.368 12.7845 10.368 12.7757 10.8961C12.7845 11.4242 13.567 11.4242 13.5757 10.8961Z"
                    fill="#213547"
                    fillOpacity="0.8"
                  />
                </svg>
              </CLButton>
            </CLTooltip>
          </div>
        )}

        {/* Comment Indicator */}
        {/* <div className="flex gap-1 justify-center rounded-full w-6 h-6 items-center">
          <div className='text-xs'>2</div>
          <svg
            className="w-3 h-3"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 10V1C0 0.725 0.0979167 0.489583 0.29375 0.29375C0.489583 0.0979167 0.725 0 1 0H9C9.275 0 9.51042 0.0979167 9.70625 0.29375C9.90208 0.489583 10 0.725 10 1V7C10 7.275 9.90208 7.51042 9.70625 7.70625C9.51042 7.90208 9.275 8 9 8H2L0 10ZM1.575 7H9V1H1V7.5625L1.575 7Z"
              fill="black"
              fill-opacity="0.6"
            />
          </svg>
        </div> */}
      </div>

      {/* Task details popup */}
      <TaskDetailsPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        node={node}
        checklist={node?.checklist}
        onChangeOfStatusIndicator={onChangeOfStatusIndicator}
        isSaving={isSaving}
      />
    </>
  );
}
